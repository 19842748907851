import { post, deletes, get, downFile } from 'common/request'
export default {
  // 网格树
 gridTree:args => get('/scGridManagement/getTreeList', args),
//  分页
list:args => get('/scGridWorker/queryList', args),
// 部门人员树
deptTree:args => get('/dept/deptUserTree', args),
// 新增
add:args => post('/scGridWorker/add', args),
// 编辑
edit:args => post('/scGridWorker/update', args),
// 删除
dels:args => post(`/scGridWorker/delete?id=${args}`),
// 详情
dels:args => post(`/scGridWorker/delete?id=${args}`),
// 详情
gridDetail:args => get(`/scGridWorker/getById?id=${args}`),
//导出
export: args => downFile('/scGridWorker/export', args),
//批量删除
 deleteBatch:args => post(`/scGridWorker/deleteBatch`,args),
}
